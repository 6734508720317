@keyframes invert {
  0% {
    filter: invert();
  }

  20% {
    filter: invert();
  }

  21% {
    filter: invert(0);
  }

  40% {
    filter: invert(0);
  }

  41% {
    filter: invert();
  }

  50% {
    filter: invert();
  }

  51% {
    filter: invert(0);
  }

  60% {
    filter: invert(0);
  }

  61% {
    filter: invert();
  }

  99% {
    filter: invert();
  }

  100% {
    filter: invert(0);
  }
}

a:active {
  animation: invert .5s step-start 4;
}

.segmentLabel:not(:hover) {
  text-decoration: inherit;
}

.P .segmentLabel {
  color: #000;
  text-decoration: inherit;
}

.Ba .segmentLabel {
  color: #44f;
}

:is(.F, .A, .B) .segmentLabel {
  color: #00f;
}

.multipleHeaders {
  min-width: var(--column);
}

.byline {
  height: calc(var(--ownInfoLines) * var(--unit) );
  color: #000;
  background: #fff;
  align-items: center;
  padding: 0 1.125rem;
  display: flex;
}

.byline .bylineCompensate0 {
  top: calc(0px - var(--unit) / 5);
  line-height: 1rem;
  position: relative;
}

.byline a:link[href] {
  text-decoration: underline;
}

.byline a {
  color: #00f;
}

.byline a:hover {
  text-decoration-color: #00f;
}

.byline a:visited:not(:hover) {
  text-decoration-color: #7479ff;
}

.west .byline {
  color: #4f8;
}

.F .info {
  opacity: 1;
  pointer-events: auto;
}

.anchor {
  position: absolute;
  top: 50%;
  left: 50%;
}

.A.🁣 {
  overflow-y: hidden;
}

.A > .bounds {
  width: 100%;
  height: 100%;
  outline-offset: -.5px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.A.🁣 > .bounds {
  width: 200vw;
  left: -50vw;
}

.A.🀱 > .bounds {
  height: 200vh;
  top: -50vh;
}

.P H1 {
  white-space: nowrap;
  overflow: visible;
}

.P .info {
  opacity: 1;
  pointer-events: auto;
}

.P .info.toc li a {
  color: #55f;
  padding: .25rem;
  font-weight: bold;
  text-decoration: none;
}

.P .info.toc li a:visited {
  color: #4f4fff;
}

.P .info.toc li a:active {
  color: #000;
}

.P .info.toc li:not(.focused) {
  background: #ffffff1a;
}

.P .info.toc li:not(.focused) a:hover {
  background: #00f;
  text-decoration: underline;
  color: #fff !important;
}

.P .info.toc li.focused {
  background: #fff;
}

.peek:hover > .body {
  transform: scale(1.05);
}

:not(.peek).illustration > .body {
  width: calc(100% + 2 * var(--bleed) );
  margin-left: calc(0px - var(--bleed) );
  margin-top: calc(0px - var(--bleed) );
  overflow: visible;
}

.east, .west {
  width: var(--unit);
}

.east > .body, .west > .body {
  visibility: hidden;
  max-height: 0;
}

.🀱.nearWest.hasBody, .🀱.nearEast.hasBody, .🀱.here.hasBody {
  width: calc(var(--ownColumns) * var(--column)  + var(--ownScreens) * var(--screen) );
}

.north {
  height: var(--unit);
}

.north > .body {
  visibility: hidden;
  max-height: 0;
}

.south {
  height: var(--unit);
}

.south > .body {
  visibility: hidden;
  max-height: 0;
}

.🁣.nearWest {
  height: var(--unit);
}

.🁣.nearWest > .body {
  visibility: hidden;
  max-height: 0;
}

.🁣.nearEast {
  height: var(--unit);
}

.🁣.nearEast > .body {
  visibility: hidden;
  max-height: 0;
}

.🁣.here.hasBody {
  width: calc(var(--ownColumns) * var(--column)  + var(--ownScreens) * var(--screen) );
  height: var(--here-height);
}

.P.north > .fab {
  left: -2rem;
  top: calc(var(--nearNorth-height)  + var(--here-height)  - 2rem);
}

.P.north > a.segmentLabel:after {
  content: "↓";
  color: #fff;
  font-size: 1.8em;
  display: block;
  position: absolute;
  bottom: .275em;
  right: -.8em;
}

.P.north > a.segmentLabel:hover:after {
  content: "↑";
}

.P.west > a.segmentLabel {
  padding-bottom: 2.75em;
  position: relative;
}

.P.west > a.segmentLabel:after {
  content: "↑";
  font-size: 1.8em;
  display: block;
  position: absolute;
  bottom: .125em;
  right: .5em;
}

.P.west > a.segmentLabel:hover:after {
  content: "↓";
}

.peekLink {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.peekLink:link > ul, .peekLink:visited > ul {
  padding: .1rem .8rem;
  position: absolute;
  top: auto;
  bottom: auto;
  right: 0;
}

.peekLink > h2 {
  text-align: right;
  mix-blend-mode: color-burn;
  margin: 0;
  font-size: 5rem;
  line-height: 4rem;
  overflow: hidden;
}

.peekLink > ul {
  mix-blend-mode: screen;
  color: orange;
  transition: all .4s;
}

.peekLink > ul + ul {
  mix-blend-mode: multiply;
  color: #fff;
  text-shadow: 1px 1px #000;
  background: none;
  transition: all .1s;
}

.peekLink:hover > ul + ul {
  background: #6f00ff;
}

.peekLink:hover > ul {
  padding: .1rem 1.8rem;
}

.Accordion {
  padding-bottom: 4rem !important;
}

.Accordion > li.P {
  z-index: 9;
  overflow: visible;
}

.Accordion > .peek > .body {
  transition: transform .2s;
}

.focusIsBackground .segmentLabel h1 {
  color: #008;
  max-width: min-content;
  background: #4f8;
}

.focusIsBackground > .🀱 {
  width: var(--unit);
}

.focusIsBackground > .🀱:not(.F) > .body {
  visibility: hidden;
  max-height: 0;
}

.focusIsBackground > .🁣 {
  height: var(--unit);
}

.focusIsBackground > .🁣:not(.F) > .body {
  visibility: hidden;
  max-height: 0;
}

.P, .B, .A:not(.hasBody) {
  background: #fff;
}

/*# sourceMappingURL=index.f85b7a3d.css.map */
