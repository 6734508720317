@keyframes invert {
    0% {
        filter: invert(1);
    }

    20% {
        filter: invert(1);
    }

    21% {
        filter: invert(0);
    }

    40% {
        filter: invert(0);
    }

    41% {
        filter: invert(1);
    }

    50% {
        filter: invert(1);
    }

    51% {
        filter: invert(0);
    }

    60% {
        filter: invert(0);
    }

    61% {
        filter: invert(1);
    }

    99% {
        filter: invert(1);
    }

    100% {
        filter: invert(0);
    }
}

a:active {
    animation: invert 0.5s step-start 4;
}


.segmentLabel:not(:hover) {
    text-decoration: inherit;
}


.P .segmentLabel {
    color: black;
    text-decoration: inherit;
}

.Ba .segmentLabel {
    color: #44F;
}

:is(.F, .A, .B) .segmentLabel {
    color: #0000FF;
}


.multipleHeaders {
    min-width: var(--column);
}

.byline {
    height: calc(var(--ownInfoLines) * var(--unit));
    background: white;
    color: black;
    display: flex;
    align-items: center;
    padding: 0 1.125rem;

    .bylineCompensate0 {
        position: relative;
        top: calc(0px - var(--unit) / 5);
        line-height: 1rem;
    }

    a:link[href] {text-decoration: underline;}
    a {color:blue;}
    a:hover {text-decoration-color: blue;}
    a:visited:not(:hover) {text-decoration-color: rgb(116, 121, 255);}

}

.west .byline {
    color: #4F8;
}

.F {

    .info {
        opacity: 1;
        pointer-events: auto;
    }
}

.anchor {
    position:absolute;
    top:50%;
    left:50%;
    // outline:1rem solid pink;
}


.A {

    &.🁣 {
        overflow-y: hidden;
    }

    >.bounds {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // outline: 0.5px dashed magenta;
        outline-offset: -.5px;
        pointer-events: none;
    }

    &.🁣>.bounds {
        width: 200vw;
        left: -50vw;
    }

    &.🀱>.bounds {
        height: 200vh;
        top: -50vh;
    }
}


.B {}


.Ba {}


.P {

    H1 {
        overflow: visible;
        white-space: nowrap;
    }


    .info {
        opacity: 1;
        pointer-events: auto;
    }

    .info.toc {
        li {
            a {
                padding: 0.25rem;
                text-decoration: none;

                font-weight: bold;
                color: #5555ff;

                &:visited {
                    color: #4f4fff;
                }

                &:active {
                    color: black;
                }
            }

            &:not(.focused) {
                background: rgb(255, 255, 255, 0.1);

                a:hover {
                    text-decoration: underline;
                    background: blue;
                    color: white !important;
                }
            }
        }

        li.focused {
            background: white;
        }
    }
}




.peek {
    &:hover {
        >.body {
            transform: scale(1.05);
        }
    }
}

:not(.peek).illustration {
    >.body {
        overflow: visible;
        width: calc(100% + 2 * var(--bleed));
        margin-left: calc(0px - var(--bleed));
        margin-top: calc(0px - var(--bleed));
    }
}




.east,
.west {
    width: var(--unit);

    >.body {
        visibility: hidden;
        max-height: 0rem;
    }
}

.🀱.nearWest.hasBody {
    width: calc(var(--ownColumns) * var(--column) + var(--ownScreens) * var(--screen));
}

.🀱.nearEast.hasBody {
    width: calc(var(--ownColumns) * var(--column) + var(--ownScreens) * var(--screen));
}

.🀱.here.hasBody {
    width: calc(var(--ownColumns) * var(--column) + var(--ownScreens) * var(--screen));
}

.north {
    height: var(--unit);

    >.body {
        visibility: hidden;
        max-height: 0rem;
    }
}

.south {
    height: var(--unit);

    >.body {
        visibility: hidden;
        max-height: 0rem;
    }
}

.🁣.nearWest {
    height: var(--unit);

    >.body {
        visibility: hidden;
        max-height: 0rem;
    }
}

.🁣.nearEast {
    height: var(--unit);

    >.body {
        visibility: hidden;
        max-height: 0rem;
    }
}

.🁣.here.hasBody {
    width: calc(var(--ownColumns) * var(--column) + var(--ownScreens) * var(--screen));
    height: var(--here-height);
}




.P.north {
    >.fab {
        left: -2rem;
        top: calc(var(--nearNorth-height) + var(--here-height) - 2rem);
    }

    >a.segmentLabel {
        &::after {
            content: "↓";
            font-size: 1.8em;
            display: block;
            position: absolute;
            right: -0.8em;
            bottom: 0.275em;
            color: white;
        }

        &:hover::after {
            content: "↑";
        }
    }
}


.P.west {
    >a.segmentLabel {
        position: relative;
        padding-bottom: 2.75em;

        &::after {
            content: "↑";
            font-size: 1.8em;
            display: block;
            position: absolute;
            right: 0.5em;
            bottom: 0.125em;
        }

        &:hover::after {
            content: "↓";
        }
    }
}

.peekLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:link {
        >ul {
            position: absolute;
            bottom: auto;
            top: auto;
            right: 0;
            padding: 0.1rem 0.8rem;
        }
    }

    &:visited {
        >ul {
            position: absolute;
            bottom: auto;
            top: auto;
            right: 0;
            padding: 0.1rem 0.8rem;
        }
    }

    >h2 {
        text-align: right;
        mix-blend-mode: color-burn;
        font-size: 5rem;
        overflow: hidden;
        line-height: 4rem;
        margin: 0;
    }

    >ul {
        mix-blend-mode: screen;
        color: orange;
        transition: all 0.4s;
    }

    >ul+ul {
        background: transparent;
        mix-blend-mode: multiply;
        color: white;
        transition: all 0.1s;
        text-shadow: 1px 1px black;
    }

    &:hover {
        >ul+ul {
            background: rgb(111, 0, 255);
        }

        >ul {
            padding: 0.1rem 1.8rem;
        }
    }
}


.Accordion {
    >li.P {
        overflow: visible;
        z-index: 9;
    }

    >.peek {
        >.body {
            transition: transform 0.2s;
        }
    }

    padding-bottom: 4rem !important;
}


/*--------------- Exceptions -----------------*/

.focusIsBackground {
    .segmentLabel {
        h1 {
            background: #4f8;
            color: #008;
            max-width: min-content;
        }
    }

    >.🀱 {
        width: var(--unit);

        &:not(.F) {
            >.body {
                visibility: hidden;
                max-height: 0rem;
            }
        }
    }

    >.🁣 {
        height: var(--unit);

        &:not(.F) {
            >.body {
                visibility: hidden;
                max-height: 0rem;
            }
        }
    }
}











/*----White area----*/

.P,
.B,
.A:not(.hasBody) {
    background: white;
}